// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/Server.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navbar {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-size: cover;\r\n  height: 200px;\r\n}\r\n.navbar-brand-container .brand {\r\n  color: white;\r\n  font-weight: 500;\r\n  letter-spacing: 1px;\r\n  margin-top: 1rem;\r\n  \r\n\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/css/NavMenu.css"],"names":[],"mappings":"AAAA;EACE,yDAA2C;EAC3C,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;;;AAGlB","sourcesContent":[".navbar {\r\n  background-image: url(../assets/Server.jpg);\r\n  background-size: cover;\r\n  height: 200px;\r\n}\r\n.navbar-brand-container .brand {\r\n  color: white;\r\n  font-weight: 500;\r\n  letter-spacing: 1px;\r\n  margin-top: 1rem;\r\n  \r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
